:root{
        --main-color :#53C0D5;
        --main-color2 :#726CC2;
        --primary-color:#14B9DB;
        --background:#87D9EA;
        --contrast:#fff;
        --border-color: #EAEAEA;
}

*{margin:0; padding:0; border:0; outline:0; text-decoration:none; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; font-family:'Poppins', sans-serif;}
body,html{float:left; width:100%; /* overflow-x:hidden; */}
body{font-family:'Poppins', sans-serif; font-weight:400; font-size:15px; color:#000; overflow-x:hidden;}
.main-wrapper{float:left; width:100%;}
.container{float:left; width:100%; max-width:1290px; padding:0 15px; position: relative;  left: 50%; transform: translateX(-50%);}
.logo{float: left}
.logo img{width: auto; height: 70px;}
.MuiGrid-spacing-xs-5{ width: calc(100% + 30px) !important; margin: -15px !important;}
.MuiGrid-spacing-xs-5 > .MuiGrid-item{padding: 0 15px !important;}
 
section,#root{float:left; width:100%;}
section{padding:45px 0;}
@media screen and (max-width:1200px){
  section{padding:30px 0;}
}
/* ========================================================================= */
.tl{text-align: left !important;}
a{text-decoration:none; color:inherit;}
img,svg{float:left; max-width:100%; height:auto;}
p{float:left; width:100%; margin-bottom:15px; line-height:1.5;}
ul,li,ol{list-style:none;}
input,select,textarea,button{font-family:'Poppins', sans-serif !important;}
a{font-weight:inherit;}
.MuiRadio-colorSecondary.Mui-checked{color:var(--primary-color) !important;}
.MuiRadio-colorSecondary.Mui-checked + span{font-weight: 500 !important;}
.btn-back{float:left; display:flex;}
.btn-back svg{float:left; margin-right:10px;}
.mrgn-top-20{margin-top:20px !important;}
.mrgn-top-30{margin-top:30px !important;}
.no-service-msg{float:left; width:100%; text-align:center; color:#8395A1; padding:25px 15px; font-size: 15px;}
.err {float:left; width:100%; margin:10px 0; color:red;}
.calIcon{float:left; position:relative; height: 40px; width: 40px; border-radius: 40px; display: flex; justify-content: center; align-items: center; background-color: var(--primary-color);}
.calIcon + .MuiTextField-root{opacity:0; position:absolute;  pointer-events:none;}
.btn-link{float:left; margin-top:15px;text-decoration:underline; font-size:13px; font-weight:500;}
.float-right{float:right;}
.color-blue{color: var(--main-color);}
.MuiOutlinedInput-notchedOutline{border-color:#EAEAEA !important; border-radius:10px; -moz-border-radius:10px; -webkikt-border-radius:10px;}
.form-txt-ctrl{float:left; width:100%; margin-top:15px; font-size:13px;}
.form-txt-ctrl .btn-link{margin-top:0;}
.MuiOutlinedInput-input{padding:15.5px 28px !important;}
.MuiCheckbox-root {margin-left:2px !important;}
.MuiPickersDay-daySelected{background:var(--primary-color) !important;}
.MuiPickersCalendarHeader-iconButton{background-color:inherit !important;}
.MuiPickersCalendarHeader-iconButton svg{fill:#fff !important;}
.MuiPickersCalendarHeader-iconButton.Mui-disabled svg{fill:rgba(255, 255, 255, .5) !important;}
.MuiPickersCalendarHeader-switchHeader{margin:0 0 12px 0 !important; background:var(--primary-color) !important;}
.MuiPickersCalendarHeader-transitionContainer .MuiTypography-body1{font-family:'Poppins', sans-serif !important; font-weight:500 !important; color:#fff;}
.MuiGrid-spacing-xs-8 > .MuiGrid-item{padding: 0 10px !important;}
.MuiGrid-spacing-xs-8{margin: 0 -10px !important; width: calc(100% + 20px) !important; padding-top: 10px;}
.MuiOutlinedInput-root{border-radius:10px !important; -moz-border-radius:10px !important; -webkit-border-radius:10px !important;}
.MuiDialog-paperScrollPaper{overflow-y: hidden !important; }
.errTimeMsg{float: left; width: 100%; margin-top:50px;}
.errTimeMsg p{float: left; width: 100%; font-style: 16px; text-align: center; line-height: 1.5;}
.errTimeMsg a{color: var(--primary-color); text-decoration: underline;}

.form-note{float: left; width: 100%; margin-top: 40px; font-size: 12px; line-height: 1.5; color: #082B44; margin-bottom: 0; text-align: justify;}
.MuiToolbar-gutters{padding: 0 !important;}
.btn-mob-stick{float: left; width: 100%; position: fixed; left: 0; bottom: 0; background: #fff;  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1); padding: 10px 15px; display: flex; justify-content: center; display: none;} 
.icon svg{fill: var(--primary-color);}
.svg-icon{fill: var(--primary-color);}
.contras-icon{fill: var(--contrast);}

/*========= SLICK SLIDER ARROW ========*/
button.slick-arrow{float:left; width:46px; height:46px; position:absolute; overflow:hidden; line-height:1000px; cursor:pointer; z-index:2; top:50%; transform:translateY(-50%); border-radius:100%; box-shadow:0 0 8px rgba(0,0,0,.107545); border:0;}
button.slick-arrow.slick-prev{background:url(/images/slide-L.svg) no-repeat #fff 50% 50%; left:20px;}
button.slick-arrow.slick-next{background:url(/images/slide-R.svg) no-repeat #fff 50% 50%; right:20px;}

/*========= SLICK SLIDER DOTS ========*/
.home-banner .slick-dots{bottom: 10px;}
.home-banner .slick-dots li{width: 7px;}
.home-banner .slick-dots li button:before{color:rgba(255, 255, 255, .9);}
.home-banner .slick-dots li.slick-active button:before{color:#fff;}

/*========= PLACEHOLDER CSS ========*/
::-webkit-input-placeholder{color:#f4f4f4;}
:-moz-placeholder          {color:#f4f4f4; opacity:1;}
::-moz-placeholder         {color:#f4f4f4; opacity:1;}
:-ms-input-placeholder     {color:#f4f4f4;}

/*========= HEADING CSS ========*/
h1, h2, h3, h4, h5, h6{float:left; width:100%;font-weight:normal; margin-bottom:20px; line-height:1.3; font-family:'Poppins', sans-serif !important;}  
h1{font-size:32px;}   
h2{font-size:28px;}   
h3{font-size:24px;}  
h4{font-size:22px;}  
h5{font-size:18px;}  
h6{font-size:16px;}  

.card {
        border-radius: 8px;
        box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
}
.m-0{ margin: 0px; } 
.m-1{ margin: 4px; } 
.m-2{ margin: 8px; } 
.m-3{ margin: 12px; } 
.ml-3{ margin-left: 12px; } 
.ml-4{ margin-left: 16px; } 
.ml-5{ margin-left: 20px; } 
.mb-3{ margin-bottom: 12px; } 
.m-4{ margin: 16px; }
.p-1{ padding: 4px; } 
.p-2{ padding: 8px; } 
.p-3{ padding: 12px; } 
.p-4{ padding: 16px; }

.MuiDialogActions-root{padding: 8px 8px  20px 8px !important;}

/*========= STEPS BULLETE ========*/
.bult-steps{float:left; margin-top: -5px;}
.bult-steps li {float:left; margin-right:8px;}
.bult-steps li a{float:left; width:8px; height:8px; background:var(--contrast); border-radius:100%; -moz-border-radius:100%; -webkit-border-radius:100%; opacity:0.30;} 
.bult-steps li a.active{opacity:1;}

/*========= HEADING CSS ========*/
.rating-wrapp{ float:left;  width:100%; display:flex; align-items:center;}
.rating-wrapp span{ float:left; white-space:nowrap; padding-top:4px; font-family:'Prata';}
.rating-wrapp .star-ratings{border:none; float:left; margin: 0 4px; position:relative; flex-shrink: 0;} 
.rating-wrapp .emp-rating-wrapp{display: flex; justify-content: center; margin: 0 6px; align-items: center; max-width: 60px;} 
/* .rating-wrapp .star-ratings svg path{fill:#DFE7EC  !important;}  */

  /*========= HIGHTLIGHTS AND HOVER ========*/ 
.rating input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label{color:#F46451;} /* hover previous stars in list */ 
.rating input:checked + label:hover, /* hover current star when changing rating */
.rating input:checked ~ label:hover,
.rating label:hover ~ input:checked ~ label, /* lighten current selection */
.rating input:checked ~ label:hover ~ label{color:#f39184;} 

/* Typography */

.MuiTypography-subtitle1{
        font-weight: 600 !important;
}

/*========= BUTTON ========*/ 
button.MuiButton-root{height: 50px; font-size: 18px;  box-shadow:none; padding:7px 30px;  border-radius:10px; -moz-border-radius:10px; -webkikt-border-radius:10px; text-transform: capitalize;}
button.MuiButton-root:hover{box-shadow:none;}
button.MuiButton-root.Mui-disabled{color:var(--contrast); opacity: 0.5;}
button.btn-blue,button.btn-blue:hover{background:linear-gradient(90deg, var(--main-color) 0%, var(--main-color2) 100%); color:var(--contrast); border:0;}
.MuiButton-containedPrimary{
      color:var(--contrast);  
      background-color:var(--primary-color) !important;  
}
button.MuiButton-outlinedPrimary{
color: var(--primary-color);
border: 1px solid var(--primary-color);
}
button.MuiButton-textPrimary{
color: var(--primary-color);
}
button.MuiButton-root.MuiButton-sizeSmall{
       height: 42px; font-size: 18px; padding:4px 12px; 
}
.alert-icon button {
 background: transparent !important;
 color: rgb(244, 100, 81) !important;
}



/* ========================
          Default-dialog
======================== */

.default-dialog .MuiDialog-paper{
        margin: 25px;
}
.default-dialog .MuiDialog-paperFullWidth{
      width: calc(100% - 50px);
}



/* ========================
          HEADER
======================== */
header{float:left; width:100%; box-shadow:none !important;}
.MuiToolbar-root{justify-content:space-between; background:linear-gradient(90deg, var(--main-color) 0%, var(--main-color2) 100%);}
.header-row{ display: flex; justify-content: space-between; align-items: center; width: 100%; }
.head-right{ display:flex;}
.head-right a.call{float:left; color: var(--contrast); display:flex; align-items:center;font-family:'Prata';}
.head-right a.call svg{float:left; margin-right:7px;} 
.head-right button{margin-left:12px; height: 36px; font-size: 14px; border-radius:0px; -moz-border-radius:0px; -webkikt-border-radius:0px;}
.head-right button .MuiButton-label{font-family:'Prata' !important; text-transform:capitalize;}
.no-wrap { white-space: nowrap; }

.center-main-content{
        min-height: calc(100vh - 33px);
}



/* ========================
        HOME BANNER
======================== */
.home-banner{padding:10px 0 0 0;}
.home-banner .MuiGrid-container{align-items:center;}
.home-banner .img-box{float:left; width:100%; }
.home-banner .img-box img{float:left; width:100%;}
.home-banner .data{float:left; width:100%; padding:30px 0;}
.home-banner .data h1{ font-family:'Prata' !important; text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
.home-banner .data h5{ font-family:'Prata' !important; font-size: 15px; color: #313131;}
.home-banner .data .icon{float:left; margin-right:15px;}
.home-banner .data button{ margin-top:20px; }
.home-banner .data button span{font-family:'Prata' !important;}
.home-banner-slidebox{order: 1;}

/* ========================
        HOME ABOUT
======================== */  
.home-about {padding:20px 0;}
.home-about h4{font-family:'Prata' !important; margin-bottom:0; margin-bottom: 15px;} 
.home-about h5{font-weight:500; display: flex; align-items: center; font-size: 15px; font-family:'Prata' !important;}
.home-about h5 i{float:left; width: 24px; margin-right:10px;}
.home-about .data {float:left; width: 100%;}
.home-about .data p{float:left; width: 100%;font-family:'Prata'; text-align: justify; color: #A0A0A0;}
.home-about table{width: 100%;}
.home-about table td,.home-about table th{font-family:'Prata' !important;}
.home-about table th{text-align:left; padding:5px 0; font-weight:normal; color: #757474;}
.home-about table td{text-align:right; padding:5px 0; color:var(--primary-color);}
.home-about .map{float:left; width:100%; border-radius:7px;  -moz-border-radius: 7px;  -webkit-border-radius: 7px; overflow: hidden;}
.home-about .map iframe{float:left; width:100%; height:260px;}
.call-mobile-view{display: none;}
/* ========================
        HOME LOCATION
======================== */ 
.home-location {
        background:#ECEBF4; 
        padding: 20px 0;
        min-height: calc(100vh - 33px);
}
.home-location h2{font-weight:600; margin-bottom:0;} 
.home-location .inside{float:left; width:100%;}
.home-location .imgbox{float:left; width:100%; margin-bottom: 12px;}
.home-location .imgbox img{float:left; width:100%; max-width:none;}
.home-location .data h1{ font-family:'Prata' !important; text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
.home-location .data h5{ font-family:'Prata' !important; font-size: 15px; color: #313131;}
.home-location .data .icon{float:left; margin-right:15px;}
.home-location .data button{ margin-top:20px; }
.home-location .data button span{font-family:'Prata' !important;}
.home-location .data{float:left; width:100%;}
.home-location .data p{color:#828282;}
.home-location .MuiCardContent-root{float:left; width:100%;}
.home-location .rating{margin-left:0;}
.home-location .owner-call-view{
     display: flex;
    align-items: center;
    padding-top: 14px;
    width: 100%;
}
/* .home-location .rating-wrapp span{margin-right:10px; color:#828282;}
.home-location .rating-wrapp span:nth-last-child(2){font-weight:600; color:#000;} */
.home-location .MuiGrid-item { padding: 12px !important;}

/* ========================
        POPUP
======================== */ 
.popup-container{min-height: 460px;}
.main-popup .MuiDialog-paperWidthLg{max-width: 980px !important; width: 100%;}
.main-popup .MuiTypography-h6{display:flex; justify-content:center; flex-direction:column; color:var(--contrast); align-items:center; position:relative; font-weight:600; font-size:28px;} 
.main-popup .MuiTypography-h6 .ttl-txt{display: inline-block;}
.main-popup .MuiTypography-h6 button{position:absolute; right:0px;} 
.main-popup .MuiDialogTitle-root{padding:12px 16px; background:linear-gradient(90deg, var(--main-color) 0%, var(--main-color2) 100%); }
.main-popup .MuiDialogContent-root{min-height:63vh; overflow-x:hidden; padding: 10px 20px;}
.btn-book-now-wrapp{padding-right: 30px;}
.main-popup .btn-booknow{width:100%; margin-top: 8px;}
.main-popup .btn-booknow .MuiButton-label{white-space: nowrap;}
.main-popup .btn-back{position:absolute; left:0px; font-size:15px; font-weight:400;}
.main-popup .btn-reschedule{border-color: var(--primary-color);}
.main-popup .MuiDialog-paperScrollPaper{max-height: unset; height: calc(100% - 0px);}

.sr-payment-form .sr-combo-inputs-row{
        display: flex;
        align-items: center;  
}
.sr-payment-form .sr-input{
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 14px 10px;
        margin: 6px 6px;
}
.sr-payment-form .sr-input::placeholder{
       color:#a2a2a2;
       font-weight: 300;
       font-size: 16px;
       letter-spacing: 0.9;
}
.sr-payment-form .sr-name-input { padding: 11px 10px; width: 100%; font-size: 16px; }

.dialog-title{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 14px;
  display: flex;
  align-items: center;
}
.dialog-title .close-btn{
        padding: 0; 
        flex-shrink: 0; 
        margin-left: 8px;  
}

.dialog-title h6{
        font-size: 18px;
        width: 100%;
        text-align: center;   
        flex-shrink: 1;
}

/*======= Salon Policy =========*/
.policy-dialog-title h6 {text-align: left; margin-left: 8px; }
.policy-action{
        flex-direction: column;
        padding: 12px 16px;
}
.policy-action .MuiFormControlLabel-root{
      width: 100%;
      margin-bottom: 8px;
}
.policy-action .MuiIconButton-label{
      color: var(--primary-color);
}

/*======= Booking Image POPUP =========*/

.popup-content{
        padding: 0 !important;
}
.popup-content .content{
     position: relative
}
.popup-content .popup-close-btn{
     position: absolute;
     top: 5px;
     right:5px;
     background-color: rgba(0, 0, 0, 0.4);
}
.popup-content .popup-close-btn svg{ color: #fff;}
.popup-content .popup-image{
        width: 100%;
        height: auto;
        min-height: 300px;
        object-fit: cover;
}



/*======= DAY LIST =========*/
.react-multi-carousel-list { float:right; width:calc(100% - 60px); border:1px solid rgba(189, 189, 189, 0.4); padding:10px; border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px; overflow:hidden; position:relative;}
.react-multi-carousel-track {float:left;  display:flex; }
.react-multi-carousel-track  li{float:left; /* width:auto !important; */}
.react-multi-carousel-track  li span{float:left;width:100%;}
.react-multi-carousel-track  li a{float:left; padding:0 15px; font-weight:500; position:relative;  color:rgba(8, 43, 68, 0.7); text-align:center;}
.react-multiple-carousel__arrow {float:left;width:20px; height:20px;   border-radius:100%; -moz-border-radius:100%; -webkit-border-radius:100%; position:absolute; top:0;}
.react-multiple-carousel__arrow--right{background:url(/images/slider-R.png) no-repeat 50%#fff; right:0;}
.react-multiple-carousel__arrow--left{background:url(/images/slider-L.png) no-repeat 50% #fff; left:0;}

/*======= CATEGORY LIST =========*/
.category-slider{float:left; width:100%;}
.category-slider .slick-list{float:left; width:100%; border:1px solid rgba(189, 189, 189, 0.4); padding:14px 30px 13px; border-radius:10px; -moz-border-radius:10px;  -webkit-border-radius:10px; padding: 14px 40px;}
.category-slider .slick-slide{float:left;}
.category-slider .slick-slide > div{float:left;}
.category-slider .slick-slide a{float:left; padding:0 20px 0 10px; font-weight:600; position:relative; text-align:center; color:rgba(8, 43, 68, 0.7)}
.category-slider .slick-slide a.active{ border-color:var(--primary-color); color:var(--primary-color);}
.category-slider .slick-slide a:after{content:"/"; float:left; position:absolute; right:0; top:0;}
.category-slider .slick-slide:last-child a:after{display:none;}
.category-slider button.slick-arrow{float:left;width:40px; height:calc(100% - 2px); border-radius:7px; -moz-border-radius:7px; -webkit-border-radius:7px; position:absolute; top:1px;  box-shadow:none; transform:none;}
.category-slider button.slick-arrow.slick-next{background:url(/images/slider-R.png) no-repeat 50%#fff; right:2px;}
.category-slider button.slick-arrow.slick-prev{background:url(/images/slider-L.png) no-repeat 50% #fff; left:2px;}

/*======= TIME LIST =========*/
.time-slider{float:right; width:calc(100% - 50px); padding:0 30px;}
.time-slider .slick-list{float:left; width:100%;}
.time-slider .slick-slide{float:left; padding:0 3px;}
.time-slider .slick-slide a{text-align:center; font-weight:400; font-size:13px; line-height: 1.2; padding:2px 7px; border:1px solid #E6EAEC; border-radius:10px; -moz-border-radius:10px;  -webkit-border-radius:10px; color:#000;}
.time-slider .slick-slide a.active{border-color:var(--primary-color); color: var(--primary-color);}
.time-slider .slick-slide a.active span{color:var(--primary-color);}
.time-slider .slick-slide a span{float:left; width:100%; color:#8395A1;} 
.time-slider .slick-slide a:after{display:none;}
.time-slider button.slick-arrow{float:left;width:20px; height:calc(100% - 2px); border-radius:0; -moz-border-radius:0; -webkit-border-radius:0; position:absolute; top:1px;  box-shadow:none; transform:none;}
.time-slider button.slick-arrow.slick-next{background:url(/images/slider-R.png) no-repeat 50%#fff; right:1px;}
.time-slider button.slick-arrow.slick-prev{background:url(/images/slider-L.png) no-repeat 50% #fff; left:1px;}
.hide-in-xs { display: block; }
.display-in-xs { display: none;}  
.hide-in-md { display: block; }
.display-in-md { display: none;}  


/*======= SEARCH BOX =========*/
.search-box{float:left; width:100%; position:relative; margin-bottom:20px;}
.search-box .MuiTextField-root{float:left; width:100%;}
.search-box .MuiTextField-root .MuiTextField-root,
.search-box .MuiTextField-root .MuiOutlinedInput-input,
.search-box .MuiTextField-root .MuiOutlinedInput-input,
.search-box .MuiTextField-root .MuiFormLabel-root{padding-left:50px !important; background:#fcfcfc;}
.search-box .MuiTextField-root .MuiFormLabel-root.Mui-focused,
.search-box .MuiTextField-root .MuiFormLabel-root.MuiFormLabel-filled{padding-left:0px !important;}
.search-box svg{position:absolute; left:20px; top:50%; transform:translateY(-50%); z-index:2;fill:#80919E;}
.search-box .MuiTextField-root .MuiInputLabel-outlined {transform: translate(13px, 100%) scale(1);}

/*======= LIST OF RADIO =========*/ 
.list-of-radio-wrapp{float:left; width:100%;}
.list-of-radio-wrapp h3,.list-of-radio-wrapp h5{margin-bottom:0; color:#000; font-size:16px; font-weight:500;}
.list-of-radio,.MuiFormGroup-root{float:left; width:100%;}
.list-of-radio{overflow:auto;}
.list-of-radio li {float:left; padding:8px 0; width:100%; border-bottom:1px solid #ddd;}
.list-of-radio li:last-child{ border-bottom:none; }
.list-of-radio li .amt{float:right; color:#000; padding-top:13px;}
.list-of-radio li .time{float:right; margin-right:10px; padding-top:13px; color: #8395A1;}
.list-of-radio li .job{float:right; color:#8395A1;}
.list-of-radio li p{float:left; width:100%; padding-left:42px; color: #8395A1; font-size: 12px; font-weight: 300; line-height: 1.5;}
.list-of-radio li .MuiFormControlLabel-root{float:left; padding-left: 9px;}
.list-of-radio li .MuiFormControlLabel-label{float:left; font-weight:400; color:#000; font-family:'Poppins', sans-serif; font-size: 15px;}

/*======= SIDEBAR POPUP =========*/  
.popup-sidebar{float:left; width:100%;  border:1px solid rgba(189, 189, 189, 0.2); border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px;}
.popup-sidebar .popup-sidebar-ttl-info{float:left; width:100%; padding:10px 15px; text-align:center; color:#000; border-bottom:1px solid #F4F4F4;}
.popup-sidebar .popup-sidebar-ttl-info .rating-wrapp{display:flex; justify-content:center; margin-top:10px;}
.popup-sidebar .popup-sidebar-ttl-info h3{font-size: 20px; margin-bottom: 15px;}
.popup-sidebar .popup-sidebar-ttl-info span{font-size:15px; }
.popup-sidebar .popup-sidebar-tbl{float:left; width:100%; max-height:560px; overflow: auto;}
.popup-sidebar .popup-sidebar-tbl table{float:left; width:100%;  border-collapse:collapse;}
.popup-sidebar .popup-sidebar-tbl table td{ padding:8px 8px; vertical-align: text-top;}
.popup-sidebar .popup-sidebar-tbl table td  button{ padding:0; margin: 0px 8px;}
.popup-sidebar .popup-sidebar-tbl table td label{width:100%; color:#000;}
.popup-sidebar .popup-sidebar-tbl table td .action-and-price{color:#000; white-space: nowrap;}

.popup-sidebar .popup-sidebar-tbl table td span{display:inline-block; width:100%; color:#92A2AC; font-size:14px;}
.popup-sidebar .popup-sidebar-tbl table td strong{display:inline-block; width:100%; font-weight:700;}
.popup-sidebar .popup-sidebar-tbl table td:last-child{text-align: right;}
.popup-sidebar .popup-sidebar-tbl table .ttl-amt{ border-top:1px solid #f4f4f4;}
.popup-sidebar .popup-sidebar-tbl table .deposit-amount label{ white-space: normal; align-items: left;}
.popup-sidebar .popup-sidebar-tbl table .deposit-amount td{ text-align: left;}
.popup-sidebar .popup-sidebar-tbl table td .truncate{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 220px; }


.popup-sidebar .popup-sidebar-time{float:left; width:100%; padding:15px; color:#000; border-bottom:1px solid #F4F4F4;}
.popup-sidebar .popup-sidebar-time strong{font-weight:400; color:#6B808F;}
.popup-sidebar .popup-sidebar-time p{margin-bottom:0;}

/*======= TECH LIST =========*/  
.list-of-technicians{ width:100%; padding: 0;}
.list-of-technicians li.item{width:100%; display: flex; align-items: center; padding:10px;}
.list-of-technicians li.item .img-box{width:40px; height:40px; border-radius:100%; -moz-border-radius:100%; -webkikt-border-radius:100%; overflow:hidden; margin-right: 8px;}
.list-of-technicians li.item .employee-list-item{ padding-left: 8px; width: 100%; display: flex; align-items: center; justify-content: space-between;}
.list-of-technicians li.item .img-box + .MuiFormControlLabel-root{ width: 100%; padding-left: 0; margin: 0;}
.list-of-technicians li.item .img-box + .MuiFormControlLabel-root span.MuiFormControlLabel-label{ width: 100%;}
.list-of-technicians li.item .left-job{display: flex; font-size: 14px; color: #6B808F; align-items:center; margin-left: 8px; justify-content: flex-end;}
.list-of-technicians li.item .rating-wrapp{width:auto; margin-top: 0px;}
.list-of-technicians li.item .MuiSvgIcon-root{width:26px; height: 26px;}

/*======= TIME LIST =========*/  
.list-of-time{display:flex; flex-wrap:wrap;}
.list-of-time li{ border: none;  max-width: 29%; margin: 0 8px;}
.list-of-time li .MuiFormControlLabel-label{font-weight:400; white-space: nowrap;}
.list-of-time li .active .MuiFormControlLabel-label {color: var(--primary-color)}
.list-of-time li .time-selection-btn {padding: 8px; font-size: 16px; width: 100%; border-color: rgba(0, 0, 0, 0.4); text-transform: unset; }
.list-of-time li.active .time-selection-btn { border-color: var(--primary-color); background-color: var(--primary-color); color: var(--contrast);   font-weight: 700;}

/*======= TIME LIST =========*/   
.form-box{float:left; width:100%; max-width:560px; position:relative;
         /* left:50%; transform:translate(-50%);  */
         padding-left: 65px;}
.form-box-inside{ width: 100%; float: left; display: flex; flex-direction: column;}
.form-box h5,.form-box h3{margin-bottom:22px;}
.form-box .fm-ttl{float:left; width:100%; margin-bottom:5px; color:#082B44; font-size:14px;} 
.form-box .fm-ttl .err{float: none; width: auto; display: inline-block; margin-left: 10px;}
.form-box .MuiFormControl-root{float:left; width:100%; margin-bottom:22px;} 
.form-box button,.form-box button:hover{width:100%; text-align:center;  color: var(--contrast);  border-radius:10px; -moz-border-radius:10px; -webkikt-border-radius:10px; background: var(--primary-color);}
.enter-phone-no .MuiFormControl-root{margin-bottom:25px;}
.enter-phone-no h5{font-weight: 400; font-size: 16px;}
.enter-phone-no button.MuiButton-root{margin-top:10px;}
.insert-activate-code .MuiFormControl-root{margin-bottom:25px;}
.insert-activate-code button.MuiButton-root{margin-top:10px;}

/*======= APPONTMENT DETAILS =========*/   
.appointment-details{float:left; width:100%; max-width:560px; position:relative; left:50%; transform:translate(-50%); padding-left: 65px;}
.appointment-details h3{margin-bottom:15px; font-size: 20px; font-weight: 400;}
.appointment-details h3 span.name{color:var(--primary-color);}
.appointment-details h5{ font-size: 18px; font-weight: 400;}
.appointment-details .time-list{width:100%; padding:0; margin-top:15px; max-height: 475px; overflow-y: auto; overflow-x: hidden;}
.appointment-details .time-list li{display: flex;  font-weight: 400;  font-size:16px; border-radius: 4px;}
.appointment-details .time-list li a{width: 100%; display: flex; justify-content: space-between; align-items: center; }
.appointment-details .time-list li a.active{color:var(--primary-color);}
.appointment-details .time-list li a .pending-label{color: #000; padding: 4px 8px;  font-size: 14px; font-weight: 600; background-color: rgba(255, 193, 7, 0.16); border-radius: 6px; color: rgb(183, 129, 3); margin-right: 16px; }
.appointment-details .btn-blue{margin-top:20px; height: 45px; clear:both; float:left; font-size:17px; margin-top: 20px; }
.upcoming-popup-container .btn-box button{ height: 45px; font-size:17px;}

.btn-box{ float:left; width: 100%; display: flex; justify-content: space-between; margin-top: 15px;}
.btn-box button{font-size:20px;}
button.btn-cancel,button.btn-cancel:hover{background: #F46451;}
.text-area-note {width: 100%;}
.text-area-note textarea {padding: 0 !important;}
.update-not-btn { 
        width: 100%;
        margin-top: 10px !important;
        padding: 6px 6px !important;
}
.update-not-btn .MuiButton-label{ 
        justify-content: flex-start;
}
/*======= Footer =========*/ 
footer{float:left; width:100%; padding:5px 0; background:var(--background); color:var(--contrast);}
footer .container{    display: flex; justify-content: space-between; align-items: center;}
.copyright{float:left; font-family:'Prata'; font-size: 12px; margin: 0;}
.social-links{float:right; display:flex;}

.msg-cancel{float: left; width: 100%; margin-top:20px;}
.msg-cancel h6{margin-bottom: 0; text-align: center;}
.msg-cancel .btn-box{justify-content: center;}
.msg-cancel .btn-box button{margin: 0 10px;}
.msg-cancel .btn-box button.btn-no{background: var(--primary-color);}


.mob-data{float: left; width: 100%; padding-right: 10px; display: none; }
.mob-data span{float: left; font-size: 12px;}
.mob-data span.pdngL5{padding-left: 5px;}
.mob-data span.amt{font-weight: 500; float: right;}
.mob-data .mob-data-btm{float: left; width: 100%; font-size: 12px; color: #8395A1; margin-bottom: 0;}
.mob-data .mob-data-btm p{float: none; width: auto; display: inline-block; font-size: 12px; color: #8395A1; margin-bottom: 0;}
.mob-data .mob-data-btm span{float: left; width: 100%;}
.mob-data .mob-data-btm strong{display: inline-block; font-weight: normal; color: #082B44;}
/* .mob-data .mob-data-btm strong:last-child{float: right;} */


/* Owner */
.salon-cards{
        background-color: #fff;
        padding: 16px;
        margin-bottom: 20px;
        display: flex;
        height: 100%;
        flex-direction: column;
        cursor: pointer;
}

/* ================================================================================================
                                R E S P O N S I V E
================================================================================================ */ 
@media screen and (max-width:959px){
        h1{font-size:26px;}   
        h2{font-size:22px;}   
        h3{font-size:20px;}  
        h4{font-size:18px;}  
        h5{font-size:15px;}  
        h6{font-size:14px;}  
       .logo img{width: auto; height: 70px;}
       .head-right button{height: 30px; font-size: 12px;}
       button.MuiButton-root{padding: 2px 20px;}
       button.MuiButton-root.MuiButton-sizeSmall{
       height: 42px; font-size: 16px; padding:4px 12px; 
}


       .MuiToolbar-regular {min-height: 50px;}
       .home-banner .data button{padding: 10px 20px 7px;}
       button.slick-arrow{width: 36px; height: 36px;}
       button.slick-arrow.slick-prev{left: 15px;}
       button.slick-arrow.slick-next{right: 15px;}
       .main-popup .MuiTypography-h6{font-size:22px;}
       .bult-steps{margin-top: 0;}
       .bult-steps li a{width: 7px; height: 7px;}
       .MuiDialogTitle-root{padding:5px 15px;}
       .MuiIconButton-root{padding: 5px;}
       .list-of-radio{height: auto; padding-right: 0;}
       .home-about .data{margin-top: 20px;}
       header .container{display: flex; justify-content: space-between; align-items: center;}
       .main-popup .MuiTypography-h6 .ttl-txt{font-size: 18px; font-weight: 500;}
       .popup-sidebar{margin-top: 20px;}
       .step-4-cart .popup-sidebar{margin-bottom: 20px; margin-top: 0;}
       .btn-book-now-wrapp{padding-right:15px;}
       .form-box,.appointment-details{padding-left: 0;}
       .list-of-radio-wrapp{margin-top: 0 !important;}
       .form-note{margin-top: 20px !important;}
       .enter-phone-no .MuiFormControl-root{margin-bottom: 15px;}
       .MuiOutlinedInput-input{height: 10px 20px !important;}
   .popup-sidebar .popup-sidebar-tbl table td .truncate{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 400px; width: unset; }
     /* .form-box {
                margin-bottom: 0;
        } */
}
@media screen and (max-width:767px){
        .hide-in-mob{display: none !important;}
        .hide-in-md { display: none; }
        .display-in-md { display: block;}
        .mob-data{display: block; padding-left:15px;}
        .main-popup .MuiDialog-paperScrollPaper{max-height:unset !important; height: 100%; width: 100%; margin: 0;}
        .main-popup .add-anther-person-mob button{width: 100%; margin-top: 12px;}
        .main-popup .MuiDialogContent-root{overflow-x:hidden; padding: 10px 12px;}
        .upcoming-popup-container {
                padding-bottom: 140px;
        }
        .fixed-in-mob {
                position: fixed;
                bottom: 30px;
                left: 12px;
                right: 12px;
                background: #fff;
        }
        .form-box {
                margin-bottom: 20px;
        }
}
@media screen and (max-width:640px){ 
        .head-right a.call{display: none;}
        .call-mobile-view{display: flex; align-items: center; padding: 4px 0; width: 100%;}
        .head-right button .MuiButton-label{font-size: 12px;}
        .MuiButton-root{font-size: 16px;}
        .copyright{font-size: 12px; width: 100%; text-align: center; margin-bottom: 0px; }
        .social-links{width: 100%; display: flex; justify-content: center; display: none;}
        .main-popup .MuiTypography-h6{font-size:18px;}
        .category-slider .slick-list .slick-track{display: flex; flex-direction: row;}
        .category-slider .slick-slide a{font-family: arial; padding: 0 15px 0 10px; border:1px solid rgba(189, 189, 189, 0.4); padding:6px 10px; border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px; white-space: nowrap;}
        .category-slider .slick-slide a:after{display: none;}
        .category-slider .slick-list{padding:0px; border: none; border-radius: 0;}
        .category-slider .slick-slide{padding: 0 3px;}
        .category-slider .slick-slider,.category-slider .slick-track{float: left; width: 100%;}
        .category-slider .slick-slide a.active{ border-color:var(--primary-color);background-color:var(--primary-color); color:var(--contrast);}
        /* .category-slider .slick-slide > div{padding: 0 3px;} */
        .search-box .MuiTextField-root .MuiFormLabel-root{font-size: 16px;}
        .popup-sidebar .popup-sidebar-ttl-info h3{font-size: 16px; margin-bottom: 10px;}
        .popup-sidebar .popup-sidebar-ttl-info span{font-size: 14px;}
        .list-of-radio li .MuiFormControlLabel-root{width: 82%; margin: 0; padding-left: 0;}        
        .time-popup .list-of-radio li .MuiFormControlLabel-root{width:100%; padding: 0;}
        
        .btn-back span{display: none;}
        /* .list-of-technicians li.item .job{display: none;}
        .list-of-technicians li:first-child .job{display: block !important;} */

        .policy-dialog-title h6 {font-size: 16px;  }
        .home-location {
                min-height: calc(100vh - 26px);
        }
        /* .popup-content .popup-image{
                width: 100%;
                height: auto;
                min-height: 300px;
                object-fit: cover;
        } */
}
@media screen and (max-width:599px){ 
        h1, h2, h3, h4, h5, h6{margin-bottom: 10px;}
        .home-about .map{margin-bottom:20px;}
        .home-about .data{margin-top:20px;}
        .home-banner-slidebox{order:0;}
        .home-banner .data{padding: 0;}
        .home-banner .data h5{margin: 6px 0; display: flex; align-items: center;}
        .center-wrapper{padding-bottom:62px;}
        /* body{padding-bottom:70px;} */
        button.MuiButton-root{height: 40px; font-size: 18px;}
        .home-banner .home-banner-slidebox{padding:12px 0 !important;}
        .MuiGrid-spacing-xs-3 {width: calc(100% + 32px); margin: 0 -16px;}
        .container{padding: 0 12px;}
        .btn-mob-stick{display: flex;}
        .home-about h5 i{width: 18px;}
        .home-about table th{padding: 3px 0;}
        .home-about .map iframe{height: 175px;}
        body{font-size: 15px;}
        .home-about h4,.home-about h5,.home-banner .data h5,.rating-wrapp span{font-size: 14px;}
        .home-banner .data h1{font-size: 20px;}
        .home-banner .img-box img {max-height: 240px; object-fit: cover;}
        .MuiInputBase-input{font-size: 15px;}
        .list-of-radio li .MuiFormControlLabel-label{font-size: 15px;}
        .category-slider .slick-slide a{font-size: 17px;}
        .list-of-radio li .amt{padding-top: 8px; font-size: 15px;}
        .list-of-radio li .time{padding: 0 0 5px 52px; width: 100%; font-size: 15px;}
        .main-popup .MuiTypography-h6 .ttl-txt{margin-top: 0;}
        .main-popup .MuiTypography-h6 button{margin-right: -15px;}
        .main-popup .btn-booknow{width: auto; float: right;}
        .rating-wrapp .star-ratings svg{width: 15px !important; height:15px !important;}
        .list-of-technicians li.item .rating-wrapp{margin-top: 0px;}
        .list-of-technicians li.item .job{margin-top: 0;}
        .appointment-details .btn-blue{font-size: 15px; width: 100%;}
        .time-slider{padding: 0;}
        .list-of-time li{ max-width: 28%;}
        .list-of-time li .time-selection-btn {padding: 6px; font-size: 14px; }
        .enter-phone-no h5{font-size: 15px;}
        .enter-phone-no .MuiInputBase-input{font-size: 15px;}
        .popup-sidebar-wrapp{margin-top: 0 !important;}
        .sr-payment-form .sr-input{ margin: 6px 4px; }
        .main-popup .MuiDialog-paper{ margin: 0 !important }
}
@media screen and (max-width:420px){
        .head-right button .MuiButton-label{font-size: 14px; margin: 0;}
        .hide-in-xs { display: none; }
        .display-in-xs { display: block;}
       
        .datepicker-full-width {margin: -8px -20px 0px;}
       .datepicker-full-width .MuiPaper-rounded{
             border-radius: 0;   
       }
       .datepicker-full-width .MuiPaper-elevation1{
             box-shadow: none;
             border-bottom: 1px solid var(--border-color) ;   
       }
       .datepicker-full-width .MuiPickersCalendar-week div{ width: 14%; height: 42px; }
       .datepicker-full-width .MuiPickersCalendarHeader-dayLabel{ width: 14%;  text-align: left;}
       .datepicker-full-width .MuiPickersCalendarHeader-daysHeader{ padding-left: 16px; }
       .datepicker-full-width .MuiTypography-caption{ font-size: 16px; }
       .datepicker-full-width .MuiPickersDay-day .MuiIconButton-label p{ font-size: 18px; }
       .datepicker-full-width .MuiPickersCalendarHeader-iconButton { padding: 8px 16px; }
       .datepicker-full-width .MuiPickersCalendarHeader-iconButton .MuiSvgIcon-root{ font-size: 32px; }
       .MuiPickersCalendar-transitionContainer{margin-left: 6px; margin-right: 4px; min-height: 250px  !important;}
       /* .MuiDialog-paperFullWidth{ width: calc(100% - 40px); }
       .MuiDialog-paper{margin: 20px;} */
}
@media screen and (max-width:400px){
        .main-popup .MuiDialog-paperWidthLg{margin: 0;}
        .MuiDialog-paperScrollPaper{max-height: 100% !important;}
        .mob-data span{font-size: 14px;}        
        .mob-data{ padding:0px;}
        .btn-book-now-wrapp { padding-right: 0px;}
}
@media screen and (max-width:359px){        
        .btn-book-now-wrapp{padding-right: 0;}
        .mob-data{padding-left: 0;}
        button.MuiButton-root{padding: 2px 15px; font-size: 16px;}
        .list-of-radio li .MuiFormControlLabel-label { font-size: 15px; }
        .list-of-time li .MuiButtonBase-root{ padding-right: 5px; }
}